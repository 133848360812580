@use "variables" as *;

button {
    outline: none;
    min-height: 45px;
    cursor: pointer;

    &:hover {
        outline: none;
    }

    &:focus {
        outline: none;
        box-shadow: none !important;
    }

    &:active {
        outline: none;
        box-shadow: none !important;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}

.btn {
    cursor: pointer;
    padding: 0.4rem 0.85rem;
    outline: none;
    min-height: 45px;

    &:hover {
        outline: none;
    }

    &:focus {
        outline: none;
        box-shadow: none !important;
    }

    &:active {
        outline: none;
        box-shadow: none !important;
    }

    &:disabled {
        cursor: not-allowed;
    }
}

.btn-link {
    color: var(--mainColor);
    font-weight: bold;
    text-decoration: underline;

    &:hover {
        color: var(--hoverSecondaryColoredBtn) !important;
    }
}

.btn-primary {
    color: $color-white;
    background-color: var(--mainColor);
    border-color: transparent !important;
    font-weight: normal;

    a {
        color: $color-white;
    }

    &:hover {
        background-color: var(--hoverPrimaryColoredBtn) !important;
    }
}

.btn-outline-primary {
    border-color: var(--mainColor);
    color: var(--mainColor);

    &:hover {
        background-color: var(--mainColor);
        color: $color-white;
        border-color: transparent;
    }
}

.btn-secondary {
    color: var(--fontColorOnSecondaryColor);
    background-color: var(--secondaryColor);
    font-weight: normal;
    border: none;

    a {
        color: $color-white;
    }

    &:hover {
        background-color: var(--hoverSecondaryColoredBtn);
    }
}

.btn-success {
    background-color: $color-green;
    color: $color-white;
    font-weight: normal;
    border: none;

    &:hover {
        background-color: $color-green-rollover;
        border: none;
    }
}

.btn-danger {
    background-color: $color-red;

    &:hover {
        background-color: $color-red-rollover;
    }
}

.btn-group-edit {
    min-width: 160px;

    .button {
        border-width: 1px;
    }

    .btn-main {
        min-width: 135px;
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .btn-ellipsis {
        min-width: 25px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.rounded {
    border-radius: 999em !important;
}

.btn-loading {
    position: relative;

    &:before {
        content: "";
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        margin-left: -10px;
        border-radius: 50%;
        border: 2px solid white;
        border-top-color: black;
        animation: spinner 0.8s linear infinite;
    }
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}
