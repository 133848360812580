ngb-pagination {
    .page-item .page-link {
        color: var(--mainColor);
    }

    .page-item.disabled .page-link {
        color: var(--gray);
    }

    .page-item.active .page-link {
        background-color: var(--mainColor);
        border-color: var(--mainColor);
    }
}
