/*------------------------------------*\
  # Color variables
\*------------------------------------*/
$color-icon-dark-grey: #010101;
$color-black: #000;
$color-black-font: #1a1d1e;
$color-dark-font: #666;
$color-notice-font: #707070;
$color-placeholder-font: #9e9e9e;
$color-icon-grey: #8A8D8F;
$color-dark-grey: #95989A;
$color-dark-grey-rollover: #CCCCCC;
$color-light-grey-rollover: #E0E0E0;
$color-light-grey-footer: #F0F0F0;
$color-lightgrey: #ededed;
$color-light-grey: #F7F7F7;
$color-white: #ffffff;

$color-main: #14387f;
$color-dark-blue-rollover: #074CB3;
$color-secondary: #009fe3;
$color-light-blue: #009EE3;
$color-blue-rollover: #00DDFF;
$color-background-light-blue: #00B8F1;
$background-light-blue: #c9f2ff;

$color-error-font: #9E1726;
$color-red: #D51E1E;
$color-red-rollover: #FF1A1A;
$color-alert: rgba(244, 66, 83, 0.7);
$color-red-light: #ffbfbf;

$color-yellow: #FFCC33;
$color-yellow-rollover: #FFEA80;

$color-green: #00B415;
$color-green-rollover: #00E626;

/*------------------------------------*\
  # Default opacity
\*------------------------------------*/
$half-opacity-transparent: 0.5;
$default-opacity-level: 0.75;

/*------------------------------------*\
  # Default border radius
\*------------------------------------*/
$default-border-radius: 6px;

/*------------------------------------*\
  # Grid breakpoints from Bootstrap
  ~/bootstrap/scss/_variables.scss
\*------------------------------------*/
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
) !default;
