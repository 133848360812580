@use "fonts" as *;
@use "variables" as *;
@use "mixins" as *;

tag-input {
    border: solid 1px var(--mainColor);
    border-left: solid 7px var(--mainColor);
    font-family: $font-family-roboto;
    font-weight: 500;
    border-radius: $input-form-border-radius !important;
    padding-left: 5px;

    .ng2-tag-input {
        border: none !important;
        padding-top: 2px !important;
        padding-bottom: 3px !important;
    }

    .ng2-tag-input__text-input:focus {
        background: none !important;
    }

    &.ng-invalid {
        &.ng-dirty,
        &.ng-touched {
            border-color: $color-red;
        }
    }

    &.ng-valid {
        &.ng-dirty,
        &.ng-touched {
            border-color: $color-green;
        }
    }

    tag {
        overflow: visible !important;
        z-index: auto !important;

        & > div {
            z-index: auto !important;
        }
    }

    tag:hover {
        box-shadow: none !important;
    }

    div {
        overflow: visible;
    }

    .ng2-tag-input__text-input {
        opacity: 0;
    }

    // NGB-Tool-Tip
    .tooltip-inner {
        background-color: $color-red !important;
        max-width: 400px;
        white-space: nowrap;
    }

    .arrow {
        &::before {
            border-bottom-color: $color-red !important;
        }
    }

    .error {
        border: $color-red 1px solid;
        border-left: $color-red 4px solid;

        .p {
            background: $color-red;
            font-size: 10px;
            color: $color-white;
        }
    }
}
