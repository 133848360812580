/*------------------------------------*\
  # Font declaration
\*------------------------------------*/
$fa-font-path: "/assets/fonts/fontawesome";
$font-family-roboto: 'Roboto', sans-serif;
$font-family-raleway: "Raleway", "Lato", "Arial", "Helvetica Neue", Helvetica, Arial, sans-serif;

/*------------------------------------*\
  # Font weights
\*------------------------------------*/
.weight-100 {
    font-weight: 100;
}

.weight-200 {
    font-weight: 200;
}

.weight-300 {
    font-weight: 300;
}

.weight-400 {
    font-weight: 400;
}

.weight-500 {
    font-weight: 500;
}

.weight-600 {
    font-weight: 600;
}

.weight-700 {
    font-weight: 700;
}

.weight-800 {
    font-weight: 800;
}

/*------------------------------------*\
  # NEED TO BE DELTED
\*------------------------------------*/
.roboto {
    font-family: $font-family-roboto;
}

.raleway {
    font-family: $font-family-raleway;
}

/*------------------------------------*\
  # Font URLs
\*------------------------------------*/
@font-face {
    font-family: 'Raleway';
    src: url(/assets/fonts/raleway/Raleway-Regular.ttf) format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url(/assets/fonts/raleway/Raleway-Light.ttf) format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: 'Raleway';
    src: url(/assets/fonts/raleway/Raleway-Bold.ttf) format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: 'Roboto';
    src: url(/assets/fonts/roboto/Roboto-Light.ttf) format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: 'Roboto';
    src: url(/assets/fonts/roboto/Roboto-Regular.ttf) format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url(/assets/fonts/roboto/Roboto-Medium.ttf) format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: 'Roboto';
    src: url(/assets/fonts/roboto/Roboto-Bold.ttf) format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/assets/fonts/lato/Lato-Regular.eot'),
    url('/assets/fonts/lato/Lato-Regular.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/assets/fonts/lato/Lato-Light.eot'),
    url('/assets/fonts/lato/Lato-Light.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/assets/fonts/lato/Lato-Bold.eot'),
    url('/assets/fonts/lato/Lato-Bold.ttf') format('truetype');
    font-weight: bold;
}
