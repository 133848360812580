@use "variables" as *;
@use "mixins" as *;
@use "fonts" as *;
@import "card-header-beam";

// ---- the card itself

$card-default-padding: 20px;

.card {
    box-sizing: content-box;
    padding: $card-default-padding;
    background-color: $color-white;
    border-radius: $default-border-radius;
    @include medium-drop-shadow;

    &.canceled {
        background-color: $color-light-grey-rollover;
    }

    .card-title {
        font-family: $font-family-raleway;
        font-size: 1.8rem;
        margin-bottom: 0;
        color: $color-black-font;
    }

    .card-subtitle {
        color: $color-dark-grey;
        font-size: 14px;
    }

    .card-content {
    }

    .card-footer {
        margin-right: -$card-default-padding;
        margin-left: -$card-default-padding;
        margin-bottom: -$card-default-padding;
        width: calc(100% + #{$card-default-padding} + #{$card-default-padding});
        background-color: $color-light-grey-footer;
        padding: 20px 20px;
        font-size: 14px;
    }

    &.blue-card {
        border: solid 2px var(--mainColor);

        .card-footer {
            background-color: var(--mainColor);
            color: $color-white;
        }
    }

    &.move-up {
        bottom: 30px;
    }

    .footer-right {
        float: right;
    }

    .footer-left {
        float: left;
        padding: 12px 0;
    }
}
