@use "variables" as *;

.tab-list-header {
    background-color: var(--mainColor);
    padding-top: 14px;
    position: relative;
    overflow: hidden;

    .tab-list-item {
        background: $color-light-grey-footer;
        color: var(--mainColor);
        border-radius: 6px 6px 0 0;
        margin-right: 5px;
        display: inline-block;
        padding: 6px 20px;
        cursor: pointer;

        &.active {
            background: $color-white;
            position: relative;
            font-weight: 600;
            z-index: 1;
        }
    }

    &:after {
        content: " ";
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        height: 12px;
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
        pointer-events: none;
    }
}
