@use "variables" as *;

.card-header-beam {
    color: $color-white;
    width: 100%;
    font-size: 14px;
    background: var(--secondaryColor);
    position: relative;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .content-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        position: relative;
        padding: 20px 0 40px 0;
        color: var(--fontColorOnSecondaryColor);

        .icon {
            width: 50px;
            height: 50px;
            background-color: var(--mainColor);
            border-radius: 50%;
            padding: 11px 6px;

            img {
                position: relative;
                width: 100%;
            }
        }

        ul {
            padding-left: 10px;
            list-style: none;
        }
    }
}
